import type { ActorRefFrom, AnyActorRef, AnyStateMachine } from "xstate";
import { interpret } from "xstate";
import { BehaviorSubject } from "rxjs";
import { ActorSystemKey } from "./system-key";

const systemMap = new Map<ActorSystemKey, AnyActorRef>();
export const system$$ = new BehaviorSubject(systemMap);

export interface System {
  register: (actorRef: AnyActorRef, id: ActorSystemKey) => string;
  registerMachine: (machine: AnyStateMachine, id: ActorSystemKey) => ActorRefFrom<typeof machine>;
  bulkRegister: (actorRefs: Record<string, AnyActorRef>) => void;
  unregister: (id: ActorSystemKey) => void;
  get: <T extends AnyActorRef>(key: ActorSystemKey) => T | null;
  getAll: <T extends AnyActorRef>(prefix: string) => T[];
  set: (key: ActorSystemKey, actorRef: AnyActorRef) => void;
}

const createActorSystem = () => {
  return {
    register: (actorRef: AnyActorRef, id: ActorSystemKey) => {
      systemMap.set(id, actorRef);
      system$$.next(systemMap);
      return actorRef.id;
    },
    registerMachine: (machine: AnyStateMachine, id: ActorSystemKey) => {
      const svc = interpret(machine);
      svc.start();
      systemMap.set(id, svc);
      system$$.next(systemMap);
      return svc as ActorRefFrom<typeof machine>;
    },
    bulkRegister: (actorRefs: Record<string, AnyActorRef>) => {
      Object.entries(actorRefs).forEach(([key, actorRef]) => {
        systemMap.set(key as ActorSystemKey, actorRef as AnyActorRef);
      });
      system$$.next(systemMap);
    },
    unregister: (id: ActorSystemKey) => {
      const actor = systemMap.get(id);
      if (!actor) return;
      actor.stop?.();
      systemMap.delete(id);
      system$$.next(systemMap);
    },
    get: <T extends AnyActorRef>(key: ActorSystemKey) => {
      return (systemMap.get(key) as T | undefined) ?? null;
    },
    getAll: <T extends AnyActorRef>(prefix: string) => {
      const items = [...systemMap.entries()].filter(([key]) => key.startsWith(prefix));
      return items.map(([_, value]) => value) as T[];
    },
    set: (key: ActorSystemKey, actorRef: AnyActorRef) => {
      systemMap.set(key, actorRef);
      system$$.next(systemMap);
    },
  };
};

export const actorSystem = createActorSystem();
